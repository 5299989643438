import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { useContext } from "react"
import { QueryClient, useQuery } from "react-query"
import { fetchApi } from "../Common/WebApi-utils"
import { ConnectionContext } from "../CustomHooks/ConnectionContext"

const GroupRoute2 = ({ component: Component, location, ...rest }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const { connection, setConnection } = useContext(ConnectionContext)

  const userConnectionQuery = useQuery(
    "userConnectionData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Connections", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (data) => {
        setConnection(data)
        if (data !== undefined) {
          const role = data.some((v) => v.accessRole === 2)
          console.log("role")
          console.log(role)
          //   if (!role) {
          //     navigate("/minside/")
          //     return null
          //   }
        }
      },
      onError: (_error) => {
        // error.message
      },
    }
  )

  return <Component {...rest} />
}

export default GroupRoute2
